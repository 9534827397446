export const fields = {
  name: {
    label: 'Site Name',
  },
  siteUseId: {
    label: 'Site Use Id',
  },
  sequence: {
    label: 'Sequence',
  },
  startDate: {
    label: 'Start Date',
  },
  endDate: {
    label: 'End Date',
  },
  siteUsage: {
    label: 'Site Usage',
  },
  address1: {
    label: 'Address 1',
  },
  address2: {
    label: 'Address 2',
  },
  address3: {
    label: 'Address 3',
  },
  city: {
    label: 'City',
  },
  state: {
    label: 'State',
  },
  country: {
    label: 'Country',
  },
  county: {
    label: 'County',
  },
  postalCode: {
    label: 'Postal Code',
  },
  gracePeriod: {
    label: 'Grace Period',
  },
  lateFeePercent: {
    label: 'Late Fee Percent',
  },
  lateFeeStartDate: {
    label: 'Late Fee Start Date',
  },
  paymentTerms: {
    label: 'Payment Terms',
  },
  poNumber: {
    label: 'PO Number',
  },
  poExpirationDate: {
    label: 'PO Expiration Date',
  },
  poAmount: {
    label: 'PO Amount ',
  },
  demandClass: {
    label: 'Demand Class ',
  },
  location: {
    label: 'Location',
  },
  salesRep: {
    fields: {
      email: {
        label: 'Sales Rep',
      },
    },
  },
  multiOrg: {
    label: 'Multi-Org',
  },
  primary: {
    label: 'Primary',
  },
  sourceType: {
    label: 'Source Type',
    placeholder: 'Select Source Type',
  },
  accessLevel: {
    label: 'Access Level',
    placeholder: 'Select Access Level',
  },
  status: {
    label: 'Status',
    placeholder: 'Select Status',
  },
  createdBy: {
    label: 'Created By',
  },
  createdOn: {
    label: 'Created Date',
  },
  modifiedBy: {
    label: 'Updated By',
  },
  modifiedOn: {
    label: 'Updated Date',
  },
};
