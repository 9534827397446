export const fields = {
  name: {
    label: 'Name',
    placeholder: 'Name',
    rules: 'required|string|max:20',
  },
  sourceType: {
    label: 'Source Type',
    placeholder: 'Select Source Type',
  },
  accessLevel: {
    label: 'Access Level',
    placeholder: 'Select Access Level',
  },
  status: {
    label: 'Status',
    placeholder: 'Select Status',
  },
  createdBy: {
    label: 'Created By',
  },
  createdOn: {
    label: 'Created Date',
  },
  modifiedBy: {
    label: 'Updated By',
  },
  modifiedOn: {
    label: 'Updated Date',
  },
};
