export enum REGISTRY_FILTER {
  NAME = 'Name',
  STATUS = 'Status',
  ACCESS_LEVEL = 'AccessLevel',
  SOURCE_TYPE = 'SourceType',
}

export enum REGISTRY_FILTER_BY {
  NAME = 'Name',
}

export enum ASSOCIATED_REGISTRY_FILTER {
  REGISTRY = 'Registry',
  TEAM = 'Team',
}

export enum CUSTOMS_DECAL_FILTER {
  CUSTOMS_DECAL_NUMBER = 'Customs Decal Number',
}
