import AddIcon from '@material-ui/icons/AddCircleOutline';
import {
  DATE_FORMAT,
  GRID_ACTIONS,
  IClasses,
  SettingsTypeModel,
  UIStore,
  Utilities,
  ViewPermission,
} from '@wings-shared/core';
import {
  AgGridPopoverWrapper,
  CustomAgGridReact,
  agGridUtilities,
  useAgGrid,
  useGridState,
} from '@wings-shared/custom-ag-grid';
import { ISearchHeaderRef, SearchHeaderV2 } from '@wings-shared/form-controls';
import { useUnsubscribe } from '@wings-shared/hooks';
import { CustomLinkButton, SidebarStore } from '@wings-shared/layout';
import { VIEW_MODE } from '@wings/shared';
import { ColDef, GridOptions, ICellRendererParams, RowNode, ValueFormatterParams } from 'ag-grid-community';
import { inject, observer } from 'mobx-react';
import React, { FC, ReactNode, RefObject, useEffect, useRef } from 'react';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  CUSTOMER_COMMUNICATION_FILTERS,
  ContactCommunicationFlatViewModel,
  CustomerStore,
  SettingsStore,
  customerSidebarOptions,
  useCustomerModuleSecurity,
} from '../Shared';
import Chip from '@material-ui/core/Chip';

interface Props extends Partial<ICellRendererParams> {
  customerStore?: CustomerStore;
  sidebarStore?: typeof SidebarStore;
  settingsStore?: SettingsStore;
}

const Communications: FC<Props> = ({ customerStore, sidebarStore, settingsStore }) => {
  const gridState = useGridState();
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const agGrid = useAgGrid<CUSTOMER_COMMUNICATION_FILTERS, ContactCommunicationFlatViewModel>([], gridState);
  const unsubscribe = useUnsubscribe();
  const _customerStore = customerStore as CustomerStore;
  const _settingsStore = settingsStore as SettingsStore;
  const customerModuleSecurity = useCustomerModuleSecurity();

  // Load Data on Mount
  useEffect(() => {
    sidebarStore?.setNavLinks(customerSidebarOptions(true), 'customer');
    loadInitialData();
  }, []);

  /* istanbul ignore next */
  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    _customerStore
      .getContactsNoSql()
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(response => {
        const flattenedData = response.results.flatMap(x =>
          x.communications.map(y => {
            return new ContactCommunicationFlatViewModel({
              ...x,
              contactMethod: new SettingsTypeModel({ ...x.contactMethod }),
              contactType: new SettingsTypeModel({ ...x.contactType }),
              ...y,
            });
          })
        );
        gridState.setGridData(flattenedData);
      });
  };

  const viewRenderer = (chipValues, fieldKey) => {
    if (!Array.isArray(chipValues)) {
      return;
    }
    return (
      <AgGridPopoverWrapper chipsValues={chipValues} suppressPopover={!chipValues.length}>
        <>
          {chipValues.map((x, index) => {
            let _label;
            switch (fieldKey) {
              case 'registryCommunicationAssociations':
                _label = x.registry?.name;
                break;
              case 'operatorCommunicationAssociations':
                _label = x.operator?.name;
                break;
              case 'customerCommunicationAssociatedOffices':
                _label = x.customerAssociatedOffice?.associatedOfficeName;
                break;
              case 'sites':
                _label = x.customerAssociatedSite?.name;
                break;
              default:
                _label = x?.name;
                break;
            }
            return <Chip size="small" label={_label} key={index} />;
          })}
        </>
      </AgGridPopoverWrapper>
    );
  };

  const actionMenus = (node: RowNode) => {
    const communicationId = node.data?.contactCommunicationId;
    const contactId = node.data.contactId;
    return [
      {
        title: 'Edit',
        isHidden: !customerModuleSecurity.isEditable,
        action: GRID_ACTIONS.EDIT,
        to: () => `${communicationId}/contact/${contactId}/${VIEW_MODE.EDIT.toLowerCase()}`,
      },
      {
        title: 'Details',
        action: GRID_ACTIONS.DETAILS,
        to: () => `${communicationId}/contact/${contactId}/${VIEW_MODE.DETAILS.toLowerCase()}`,
      },
    ];
  };

  /* istanbul ignore next */
  const columnDefs: ColDef[] = [
    {
      headerName: 'Communication Level',
      field: 'communicationLevel',
      valueFormatter: ({ value }) => value?.name || '',
    },
    {
      headerName: 'Contact Role',
      field: 'contactRole',
      valueFormatter: ({ value }) => value?.name || '',
    },
    {
      headerName: 'Start Date',
      field: 'startDate',
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utilities.getformattedDate(value, DATE_FORMAT.DATE_PICKER_FORMAT) || '',
    },
    {
      headerName: 'End Date',
      field: 'endDate',
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utilities.getformattedDate(value, DATE_FORMAT.DATE_PICKER_FORMAT) || '',
    },
    {
      headerName: 'Communication Categories',
      field: 'communicationCategories',
      cellRenderer: 'viewRenderer',
      cellRendererParams: {
        getViewRenderer: (rowIndex: number, node: RowNode, classes: IClasses, colDef?: ColDef) =>
          viewRenderer(node.data?.communicationCategories, colDef?.field),
      },
    },
    {
      headerName: 'Customer',
      field: 'customer',
      valueFormatter: ({ value }) => value?.name || '',
    },
    {
      headerName: 'Customer Number',
      field: 'customer',
      valueFormatter: ({ value }) => value?.code || '',
    },
    {
      headerName: 'Offices',
      field: 'offices',
      cellRenderer: 'viewRenderer',
      cellRendererParams: {
        getViewRenderer: (rowIndex: number, node: RowNode, classes: IClasses, colDef?: ColDef) =>
          viewRenderer(node.data?.offices, colDef?.field),
      },
    },
    {
      headerName: 'Sequence',
      field: 'sequence',
    },
    {
      headerName: 'Sites',
      field: 'sites',
      cellRenderer: 'viewRenderer',
      cellRendererParams: {
        getViewRenderer: (rowIndex: number, node: RowNode, classes: IClasses, colDef?: ColDef) =>
          viewRenderer(node.data?.sites, colDef?.field),
      },
    },
    {
      headerName: 'Priority',
      field: 'contactPriority',
      valueFormatter: ({ value }) => value?.name || '',
    },
    {
      headerName: 'Operators',
      field: 'operatorCommunicationAssociations',
      cellRenderer: 'viewRenderer',
      cellRendererParams: {
        getViewRenderer: (rowIndex: number, node: RowNode, classes: IClasses, colDef?: ColDef) =>
          viewRenderer(node.data?.customerCommunicationOperators, colDef?.field),
      },
    },
    {
      headerName: 'Registries',
      field: 'registryCommunicationAssociations',
      cellRenderer: 'viewRenderer',
      cellRendererParams: {
        getViewRenderer: (rowIndex: number, node: RowNode, classes: IClasses, colDef?: ColDef) =>
          viewRenderer(node.data?.customerCommunicationRegistries, colDef?.field),
      },
    },
    {
      headerName: 'Contact',
      field: 'contact',
    },
    {
      headerName: 'Contact Extension',
      field: 'contactExtension',
    },
    {
      headerName: 'Contact Name',
      field: 'contactName',
    },
    {
      headerName: 'Contact Method',
      field: 'contactMethod',
      valueFormatter: ({ value }) => value?.name || '',
    },
    {
      headerName: 'Contact Type',
      field: 'contactType',
      valueFormatter: ({ value }) => value?.name || '',
    },
    ...agGrid.generalFields(_settingsStore),
    ...agGrid.auditFields(gridState.isRowEditing),
    {
      ...agGrid.actionColumn({
        cellRendererParams: {
          isActionMenu: true,
          actionMenus,
        },
      }),
    },
  ];

  /* istanbul ignore next */
  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: {},
      columnDefs,
    });
    return {
      ...baseOptions,
      suppressCellSelection: true,
      isExternalFilterPresent: () => searchHeaderRef.current?.hasSearchValue || false,
      doesExternalFilterPass: node => {
        const searchHeader = searchHeaderRef.current;
        const {
          id,
          communicationLevel,
          contactRole,
          contact,
          contactExtension,
          contactName,
          contactMethod,
          contactType,
          customer,
        } = node.data as ContactCommunicationFlatViewModel;
        if (!searchHeader) {
          return false;
        }
        return (
          !id ||
          agGrid.isFilterPass(
            {
              [CUSTOMER_COMMUNICATION_FILTERS.COMMUNICATION_LEVEL]: communicationLevel?.name,
              [CUSTOMER_COMMUNICATION_FILTERS.CONTACT_ROLE]: contactRole?.name || '',
              [CUSTOMER_COMMUNICATION_FILTERS.CUSTOMER]: customer?.name || '',
              [CUSTOMER_COMMUNICATION_FILTERS.CUSTOMER_NUMBER]: customer?.code || '',
              [CUSTOMER_COMMUNICATION_FILTERS.CONTACT]: contact,
              [CUSTOMER_COMMUNICATION_FILTERS.CONTACT_EXTENSION]: contactExtension,
              [CUSTOMER_COMMUNICATION_FILTERS.CONTACT_NAME]: contactName,
              [CUSTOMER_COMMUNICATION_FILTERS.CONTACT_METHOD]: contactMethod?.name,
              [CUSTOMER_COMMUNICATION_FILTERS.CONTACT_TYPE]: contactType?.name,
            },
            searchHeader.searchValue,
            searchHeader.selectedOption
          )
        );
      },
    };
  };

  const rightContent = (): ReactNode => {
    return (
      <ViewPermission hasPermission={customerModuleSecurity.isEditable}>
        <CustomLinkButton variant="contained" startIcon={<AddIcon />} to="new" title="Add Communication" />
      </ViewPermission>
    );
  };

  return (
    <>
      <SearchHeaderV2
        ref={searchHeaderRef as RefObject<ISearchHeaderRef>}
        onExpandCollapse={agGrid.autoSizeColumns}
        // eslint-disable-next-line max-len
        selectInputs={[
          agGridUtilities.createSelectOption(
            CUSTOMER_COMMUNICATION_FILTERS,
            CUSTOMER_COMMUNICATION_FILTERS.COMMUNICATION_LEVEL
          ),
        ]}
        rightContent={rightContent}
        onFilterChange={() => gridState.gridApi.onFilterChanged()}
        disableControls={Boolean(Array.from(gridState.columFilters).length)}
      />
      <CustomAgGridReact isRowEditing={gridState.isRowEditing} rowData={gridState.data} gridOptions={gridOptions()} />
    </>
  );
};

export default inject('customerStore', 'settingsStore', 'sidebarStore')(observer(Communications));
