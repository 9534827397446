export enum OPERATOR_FILTER {
  NAME = 'Name',
  STATUS = 'Status',
  ACCESS_LEVEL = 'AccessLevel',
  SOURCE_TYPE = 'SourceType',
}

export enum OPERATOR_FILTER_BY {
  NAME = 'Name',
}

export enum ASSOCIATED_OPERATOR_FILTER {
  OPERATOR = 'Operator',
}
