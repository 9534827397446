export const fields = {
  includeAllRegistry: {
    label: 'Include All Registries',
  },
  registries: {
    label: 'Registry',
    placeholder: 'Select Registry',
    rules: 'required',
    value: [],
  },
  team: {
    label: 'Team',
    placeholder: 'Select Team',
    rules: 'required',
  },
  office: {
    label: 'Office',
    placeholder: 'Select Office',
  },
};
