import { modelProtection, CoreModel } from '@wings-shared/core';
import { IAPITeam } from '../Interfaces';

@modelProtection
export class TeamModel extends CoreModel {
  code: string;
  isInternal: boolean | null = null;

  constructor(data?: Partial<TeamModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: IAPITeam): TeamModel {
    if (!apiData) {
      return new TeamModel();
    }
    const data: Partial<TeamModel> = {
      ...apiData,
      id: apiData.teamId || apiData.id,
      ...this.deserializeAuditFields(apiData),
    };
    return new TeamModel(data);
  }

  static deserializeList(apiDataList: IAPITeam[]): TeamModel[] {
    return apiDataList ? apiDataList.map((apiData: IAPITeam) => TeamModel.deserialize(apiData)) : [];
  }

  // serialize object for create/update API
  public serialize() {
    return {
      id: this.id,
      name: this.name,
      code: this.code,
      isInternal: this.isInternal,
      ...this._serialize(),
    };
  }

  // required in auto complete
  public get label(): string {
    return this.name;
  }

  public get value(): number {
    return this.id;
  }
}
