export enum CUSTOMER_FILTER {
  NAME = 'Name',
  NUMBER = 'Number',
  STATUS = 'Status',
  ACCESS_LEVEL = 'AccessLevel',
  SOURCE_TYPE = 'SourceType',
}

export enum CUSTOMER_FILTER_BY {
  NAME = 'Name',
  NUMBER = 'Number',
}
