export const fields = {
  name: {
    label: 'Name',
  },
  number: {
    label: 'Number',
  },
  partyNumber: {
    label: 'Party Number',
  },
  partyAltName: {
    label: 'Party Alt Name',
  },
  partyType: {
    label: 'Party Type',
  },
  corporateSegment: {
    label: 'Corporate Segment',
  },
  classification: {
    label: 'Classification',
  },
  startDate: {
    label: 'Start Date',
  },
  endDate: {
    label: 'End Date',
  },
  region: {
    label: 'Region',
  },
  collectorName: {
    label: 'Collector Name',
  },
  creditRating: {
    label: 'Credit Rating',
  },
  paymentTerms: {
    label: 'Payment Terms',
  },
  creditAnalyst: {
    label: 'Credit Analyst',
  },
  dmClient: {
    label: 'DM Client',
  },
  riskCode: {
    label: 'Risk Code',
  },
  accountStatus: {
    label: 'Account Status',
  },
  accessLevel: {
    label: 'Access Level',
    placeholder: 'Select Access Level',
  },
  sourceType: {
    label: 'Source Type',
    placeholder: 'Select Source Type',
  },
  status: {
    label: 'Status',
    placeholder: 'Select Status',
  },
  createdBy: {
    label: 'Created By',
  },
  createdOn: {
    label: 'Created Date',
  },
  modifiedBy: {
    label: 'Updated By',
  },
  modifiedOn: {
    label: 'Updated Date',
  },
};
