import { IAPIFilterDictionary } from '@wings-shared/core';
import { TEAM_FILTER } from '../../../Shared';

export const gridFilters: IAPIFilterDictionary<TEAM_FILTER>[] = [
  {
    columnId: 'name',
    apiPropertyName: 'Name',
    uiFilterType: TEAM_FILTER.NAME,
  },
  {
    columnId: 'code',
    apiPropertyName: 'Code',
    uiFilterType: TEAM_FILTER.CODE,
  },
];
